import React from "react";
import Layout from "../components/Layout";
import { AboutOurAds } from "@tyson-foods/react-component-library";

export default class PrivacyPolicyPage extends React.Component {
  render() {
    return (
      <Layout metaTitle="About Our Ads">
        <AboutOurAds className="pt-5" />
      </Layout>
    );
  }
}